import React from 'react';
// import Dropbox from '../images/dropbox.png'
// import Logo from '../images/logo.png'
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { Link, StaticQuery, graphql } from 'gatsby';
import spacetime from 'spacetime';
import IGIcon from '../svgs/ig-icon.js';
import TwitterIcon from '../svgs/twitter-icon.js';

const date = new Date();

const handleFact = facts => {
  const s = spacetime();
  facts.forEach(fact => {
    if (fact.node.date) {
      const testS = spacetime(fact.node.date);
      if (testS.date() === s.date() && testS.month() === s.month()) {
        return fact.node.fact.fact;
      }
    }
  });

  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  const index = day % facts.length;
  const randomFact = facts[index];
  return randomFact.node.fact.fact;
};

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query facts {
          allContentfulFacts {
            edges {
              node {
                id
                fact {
                  id
                  fact
                }
                date
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div className="footer">
            <div className="rel container--xl ma x">
              <div className="mt1 footer__top__section__container py1 f fr jcb ais">
                <div className="f1 mx075 f fr">
                  <a
                    href="https://twitter.com/SuperclusterHQ"
                    target="blank"
                    className="mr075 clickable"
                    alt="Twitter"
                    aria-label="Twitter"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/superclusterhq/"
                    target="blank"
                    className="mr075 clickable"
                    alt="Instagram"
                    aria-label="Instagram"
                  >
                    <IGIcon />
                  </a>
                </div>
                <div className="f1 mx075 f aic">
                  <a
                    href="https://supercluster.com"
                    target="blank"
                    className="caps s0 clickable"
                    alt="Supercluster"
                    aria-label="Supercluster"
                  >
                    Visit supercluster.com
                  </a>
                </div>
                <div className="f1 mx075 f jce aic">
                  <ul className="f jce aic">
                    <li className="caps s0 ml2 pl1">
                      <Link to="/">Work</Link>
                    </li>
                    <li className="caps s0 ml2 pl1">
                      <Link to="/about">About</Link>
                    </li>
                    <li className="caps s0 ml2 pl1">
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="hide--m my1 p15">
                <div className="x f aic">
                  <a
                    href="https://supercluster.com"
                    target="blank"
                    className="caps h6 clickable"
                    alt="Supercluster"
                    aria-label="Supercluster"
                  >
                    <b>
                      Visit <u>supercluster.com</u>
                    </b>
                  </a>
                </div>
                <div className="x f fr mt1">
                  <a
                    href="https://twitter.com/SuperclusterHQ"
                    target="blank"
                    className="mr075 clickable"
                    alt="Twitter"
                    aria-label="Twitter"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/superclusterhq/"
                    target="blank"
                    className="mr075 clickable"
                    alt="Instagram"
                    aria-label="Instagram"
                  >
                    <IGIcon />
                  </a>
                </div>
              </div>

              <div className="footer__section__container">
                <div className="f1 footer__section f fc">
                  <h6 className="caps mb0 pb0 b">
                    WANT TO{' '}
                    <a href="mailto:info@supercluster.com" className="underline">
                      work with us?
                    </a>
                  </h6>
                  <div className="f1" />
                  <div className="pt1 show--m">
                    <div className="mb025">
                      <a
                        href="https://www.dropbox.com/sh/vmcoq13743s8arn/AABxB9_GoJ7u56J4jMZHIIKXa?dl=0&preview=Supercluster+Privacy+Policy+-+Website+-+v1.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Privacy Policy"
                      >
                        <h6 className="caps xsmall akkura m0 p0">
                          Read our Privacy Policy
                        </h6>
                      </a>
                    </div>
                    <h6 className="caps xsmall akkura m0 p0">
                      Copyright {date.getFullYear()} Supercluster LLC
                    </h6>
                  </div>
                </div>

                <div className="f1 footer__section">
                  <h6 className="caps mb0 pb0 b">GET THE SUPERCLUSTER APP</h6>
                  <div className="x f fr mt2">
                    <a
                      href="https://apps.apple.com/us/app/supercluster/id1500045135"
                      target="blank"
                      className="btn--outline caps mr1"
                      alt="Supercluster iOS App"
                      aria-label="Supercluster iOS App"
                    >
                      iOS
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.supercluster.tracker&hl=en_US&gl=US"
                      target="blank"
                      className="btn--outline caps"
                      alt="Supercluster Android App"
                      aria-label="Supercluster Android App"
                    >
                      Android
                    </a>
                  </div>
                </div>

                <div className="f1 footer__section">
                  <div className="pt2 hide--m">
                    <div className="mb025">
                      <a
                        href="https://www.dropbox.com/sh/vmcoq13743s8arn/AABxB9_GoJ7u56J4jMZHIIKXa?dl=0&preview=Supercluster+Privacy+Policy+-+Website+-+v1.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="Privacy Policy"
                      >
                        <h6 className="caps xsmall akkura m0 p0">
                          Read our Privacy Policy
                        </h6>
                      </a>
                    </div>
                    <h6 className="caps xsmall akkura m0 p0">
                      Copyright {date.getFullYear()} Supercluster LLC
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
