import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

export const ListTypes = {
  CardGrid: 'card-grid',
  CardListX: 'card-list-x',
  CardListY: 'card-list-y',
};

const SuperCard = ({
  linkTo,
  onClick = () => {},
  listType,
  children,
  className = '',
  style = {},
}) => {
  const content = (
    <div
      className={cx('', {
        super_card__content_grid: listType === ListTypes.CardGrid,
        super_card__content_list_x: listType === ListTypes.CardListX,
        super_card__content_list_y: listType === ListTypes.CardListY,
      })}
    >
      <div className={`super_card__content_wrapper ${className}`} style={style}>
        {children}
      </div>
    </div>
  );

  return linkTo ? (
    <Link
      to={linkTo}
      className={cx('cb', {
        super_card__link_grid: listType === ListTypes.CardGrid,
        super_card__link_list_x: listType === ListTypes.CardListX,
        super_card__link_list_y: listType === ListTypes.CardListY,
      })}
      onClick={onClick}
    >
      {content}
    </Link>
  ) : (
    <div
      className={cx('cb', {
        super_card__link_grid: listType === ListTypes.CardGrid,
        super_card__link_list_x: listType === ListTypes.CardListX,
        super_card__link_list_y: listType === ListTypes.CardListY,
      })}
    >
      {content}
    </div>
  );
};

export default SuperCard;
