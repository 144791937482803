import React from 'react';

export default function SVG({ className }) {
  return (
    <svg
      className={className}
      width="16px"
      height="13px"
      viewBox="0 0 16 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="sc-header_2020-06-10_A"
          transform="translate(-951.000000, -56.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <path
            d="M966.827217,57.5226957 C966.245043,57.781 965.618783,57.9556522 964.962,58.0336522 C965.632913,57.6317826 966.147826,56.995913 966.389739,56.2373913 C965.762348,56.6093043 965.067696,56.8800435 964.327826,57.0253043 C963.735478,56.3945217 962.891609,56 961.958435,56 C960.165,56 958.711826,57.4537391 958.711826,59.2471739 C958.711826,59.5015217 958.740652,59.7496522 958.795478,59.9876087 C956.096565,59.8513913 953.704,58.5587391 952.102739,56.5951739 C951.823522,57.073913 951.663565,57.6323478 951.663565,58.2269565 C951.663565,59.3534348 952.201087,60.347087 953.072652,60.9292609 C952.540783,60.9123043 952.131565,60.7664783 951.566348,60.5228696 C951.566348,60.5364348 951.566348,60.55 951.566348,60.5635652 C951.566348,62.1365652 952.721087,63.449 954.205913,63.748 C953.933478,63.8226087 953.664435,63.8621739 953.368261,63.8621739 C953.15913,63.8621739 952.96413,63.8412609 952.766304,63.8033913 C953.179478,65.0932174 954.382826,66.0320435 955.803783,66.0586087 C954.692565,66.9290435 953.295348,67.4484783 951.773783,67.4484783 C951.510957,67.4484783 951.253783,67.4332174 951,67.4026957 C952.436783,68.324 954.143739,68.8615217 955.978435,68.8615217 C961.949957,68.8615217 965.215783,63.9141739 965.215783,59.6230435 C965.215783,59.4823043 965.213522,59.3421304 965.207304,59.203087 C965.842043,58.7458261 966.391435,58.1743913 966.827217,57.5226957 Z"
            id="twitter-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}
