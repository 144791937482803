import React from 'react';
import SuperCard, { ListTypes } from '../core/super-card';

export default function LoadingCell({ index, listType = ListTypes.CardGrid }) {
  return (
    <SuperCard key={'loading-grid-' + index} listType={listType} className="">
      {index === 0 ? (
        <div className="f aic " style={{ height: '237px', width: '100%' }}>
          <div className="h4 caps cw">Searching...</div>
        </div>
      ) : (
        <div className="f1 f fc">
          <div className="fio f1" />
          <div className="f fc mt1">
            <div className="fio" style={{ height: '10px' }} />
            <div className="fio mt05" style={{ height: '10px' }} />
            <div className="fio mt1 height-1" />
            <div className="f fr mt1 jcb" style={{ height: '10px' }}>
              <div className="fio" style={{ width: '40%' }} />
              <div className="fio" style={{ width: '10%' }} />
            </div>
          </div>
        </div>
      )}
    </SuperCard>
  );
}
