import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import SanityBackgroundImage from '../core/sanity-background-image.js';
import spacetime from 'spacetime';
import cx from 'classnames';
import SuperCard, { ListTypes } from '../core/super-card';

export default function CaseStudyResult({
  caseStudy,
  onClick = () => {},
  listType = ListTypes.CardGrid,
}) {
  return (
    <SuperCard
      key={caseStudy._id}
      linkTo={`/case-study/${caseStudy.slug}`}
      onClick={onClick}
      listType={listType}
      className="fc cw"
    >
      <div className="rel article__search__card__image">
        <SanityBackgroundImage
          source={caseStudy.featuredImage.fields.file.url}
          alt={caseStudy.title}
          width={600}
          height={600 * 0.7}
          lazyLoad={listType === ListTypes.CardGrid}
        />
      </div>
      <div className="h5 py1">{caseStudy.title}</div>
      <div className="x height-1 bcb" />
      <div className="f fr aic">
        <div className="caps small article__card__date bau pt1 mr05">
          Client: {caseStudy.client}
        </div>
      </div>
    </SuperCard>
  );
}
