import React from 'react';

export default function ClearCircle({ className }) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="supercluster_database_05-21-2021"
          transform="translate(-909.000000, -108.000000)"
          stroke="#000000"
        >
          <g id="clear" transform="translate(909.000000, 108.000000)">
            <g
              id="Group"
              transform="translate(8.000080, 8.000000) rotate(45.000000) translate(-8.000080, -8.000000) translate(4.000080, 4.000000)"
            >
              <line x1="4" y1="8" x2="4" y2="0" id="Stroke-42"></line>
              <line x1="0" y1="4" x2="8" y2="4" id="Stroke-43"></line>
            </g>
            <path
              d="M8,15.5 C12.1421818,15.5 15.5,12.1421818 15.5,8 C15.5,3.85781824 12.1421818,0.5 8,0.5 C3.85781824,0.5 0.5,3.85781824 0.5,8 C0.5,12.1421818 3.85781824,15.5 8,15.5 Z"
              id="Stroke-59"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
