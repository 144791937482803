import React from 'react';
import Helmet from 'react-helmet';
import vsbl from 'vsbl';

export default class InlineVideo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.vs = null;
    this.isReady = false;
    this.isVisible = false;
  }

  componentDidMount() {
    const Plyr = require('plyr');
    let controls = ['play-large'];
    const video = this.video.current;
    if (this.props.data.showControls === true) {
      controls = [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'pip',
        'airplay',
        'fullscreen',
      ];
    }

    this.player = new Plyr(video, {
      autopause: false,
      muted: this.props.data.autoplay,
      autoplay: this.props.data.autoplay,
      vimeo: {
        controls: false,
      },
      loop: { active: true },
      qaulity: { default: 576 },
      controls: controls,
    });

    const playVideo = () => {
      if (this.props.data.autoplay) {
        this.player.decreaseVolume(1);
        this.player.decreaseVolume(1);
        this.player.decreaseVolume(1);
        this.player.decreaseVolume(1);
        this.player.play();
      }

      if (
        this.props.data.loop === true ||
        this.props.data.loop === null ||
        this.props.data.loop === undefined
      ) {
        this.player.loop = true;
        this.player.muted = true;
      } else {
        this.player.loop = false;
      }
    };

    this.player.on('ready', event => {
      this.isReady = true;
      const currentVolume = this.player.volume;
      if (currentVolume === 0) {
        this.player.volume = 1;
      }
      if (this.isVisible) {
        playVideo();
      }
    });

    this.vs = vsbl(video)(() => {
      this.isVisible = true;
      if (this.isReady) {
        playVideo();
      }
    });
    this.vs.update();
  }

  componentWillUnmount() {
    this.player && this.player.destroy();
    this.vs && this.vs.destroy();
  }

  render() {
    return (
      <div className={this.props.isInline ? 'workVideo' : 'py05 workVideo'}>
        <Helmet>
          <link rel="stylesheet" href="/plyr/plyr.css" />
        </Helmet>
        <div
          ref={this.video}
          data-plyr-provider="vimeo"
          data-plyr='{ "volume": 1 }'
          data-plyr-embed-id={this.props.data.videoId}
          preload="true"
        />
      </div>
    );
  }
}
