import contentfulAPI from './contentful';

const base = 'https://gypr7xktch.execute-api.us-east-1.amazonaws.com/prod';

export default {
  async search(search, offset = 0, size = 20) {
    const response = await contentfulAPI.getEntries({
      content_type: 'caseStudy',
      include: 8,
      limit: 1000,
      query: search,
    });

    return {
      results: response.items.map(item => ({
        ...item.fields,
        id: item.sys.id,
        _type: 'case-study',
      })),
      total: response.items.length,
      hasNextPage: false,
      query: search,
    };
  },
  async query(query, offset = 0, size = 20, sort) {
    let url = `${base}/query?q=${query}&offset=${offset}&size=${size}`;
    if (sort) {
      url = `${url}&sort=${encodeURI(sort)}`;
    }
    const response = await fetch(url);
    const json = await response.json();
    return json;
  },
};
