import React from 'react';
import { LazyImage } from 'react-lazy-images';
const builder = require('../../api/sanityUrlBuilder');

class SanityBackgroundImage extends React.PureComponent {
  constructor(props) {
    super(props);

    let src = `${this.props.source}?q=80&fl=progressive`;
    let isSVG = false;

    if (this.props.image && this.props.image.asset) {
      const sanityUrl = this.props.image.asset.url;
      isSVG = sanityUrl.endsWith('.svg') || sanityUrl.endsWith('.SVG');
      if (isSVG) {
        src = sanityUrl;
      } else {
        const width = this.props.width
          ? Math.floor(this.props.width * 2)
          : undefined;
        const height = this.props.height
          ? Math.floor(this.props.height * 2)
          : undefined;

        src = builder
          .image(this.props.image)
          .width(width)
          .height(height)
          .url();
      }
    }

    this.state = { src };
  }

  render() {
    const { src } = this.state;
    const { alt, lazyLoad } = this.props;

    return lazyLoad ? (
      <LazyImage
        src={src}
        alt={alt}
        placeholder={({ imageProps, ref }) => (
          <div ref={ref} className="sanity_background_image__loader bcg" />
        )}
        actual={({ imageProps }) => (
          <div
            className="abs fill fadeIn"
            style={{
              backgroundImage: `url(${imageProps.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        )}
      />
    ) : (
      <div
        className={`abs fill`}
        alt={alt}
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        aria-label={alt}
      />
    );
  }
}

export default SanityBackgroundImage;
