import React from 'react';

export default function SVG({ className }) {
  return (
    <svg
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="sc-header_2020-06-10_A"
          transform="translate(-978.000000, -56.000000)"
          fill="#ffffff"
        >
          <path
            d="M990.954639,59.297 C990.918557,58.524 990.78866,58.102 990.680412,57.824 C990.537113,57.455 990.365979,57.19 990.087629,56.912 C989.809278,56.633 989.54433,56.462 989.176289,56.319 C988.892784,56.211 988.475258,56.081 987.702062,56.045 C986.86701,56.009 986.615464,56 984.5,56 L983.789691,56 C982.312371,56 982.028866,56.009 981.296907,56.045 C980.524742,56.081 980.102062,56.211 979.823711,56.319 C979.45567,56.462 979.190722,56.633 978.912371,56.912 C978.63299,57.19 978.462887,57.455 978.319588,57.824 C978.21134,58.107 978.080412,58.524 978.045361,59.297 C978.009278,60.133 978,60.384 978,62.5 C978,64.616 978.009278,64.867 978.045361,65.703 C978.080412,66.476 978.21134,66.898 978.319588,67.177 C978.462887,67.545 978.63299,67.81 978.912371,68.088 C979.190722,68.367 979.45567,68.538 979.823711,68.681 C980.107216,68.789 980.524742,68.919 981.296907,68.955 C982.13299,68.991 982.384536,69 984.5,69 C986.615464,69 986.86701,68.991 987.702062,68.955 C988.475258,68.919 988.896907,68.789 989.176289,68.681 C989.548454,68.538 989.809278,68.367 990.087629,68.088 C990.365979,67.81 990.537113,67.545 990.680412,67.177 C990.78866,66.893 990.918557,66.476 990.954639,65.703 C990.990722,64.867 991,64.616 991,62.5 C991,60.384 990.990722,60.133 990.954639,59.297 M984.5,65.141 C983.040206,65.141 981.858763,63.96 981.858763,62.5 C981.858763,61.04 983.040206,59.859 984.5,59.859 C985.959794,59.859 987.141237,61.04 987.141237,62.5 C987.141237,63.96 985.959794,65.141 984.5,65.141 M988.730928,59.221 C988.205155,59.221 987.778351,58.794 987.778351,58.268 C987.778351,57.743 988.205155,57.316 988.730928,57.316 C989.256701,57.316 989.683505,57.743 989.683505,58.268 C989.683505,58.794 989.256701,59.221 988.730928,59.221"
            id="ig-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}
