import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import spacetime from 'spacetime';
import cx from 'classnames';
import ReactList from 'react-list';

import ScrollView from '../core/scroll-view';
import Slider from '../core/slider.js';
import { ListTypes } from '../core/super-card.js';

import Elastic from '../../api/elastic.js';
import Utils from '../../api/utils.js';
import useWindowSize from '../../state/useWindowSize';

import ClearCircle from '../svgs/clearCircle.js';
import SearchIcon from '../svgs/search-icon.js';
import CaseStudyResult from './case-study-result.js';
import LoadingCell from './loading-cell.js';

class Search extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      searchText: '',
      results: [],
      hasMore: false,
      loading: false,
      scrollPercentage: 0,
    };

    this.renderItem = this.renderItem.bind(this);
    this.sliderRef = React.createRef();
  }

  search(text) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (text.length === 0) {
      this.setState({
        searchText: text,
        loading: false,
        results: [],
        total: 0,
      });
    } else {
      this.setState({
        searchText: text,
        loading: true,
        total: 10,
      });
      this.timer = setTimeout(() => {
        Elastic.search(text)
          .then(({ results, total, hasNextPage, query }) => {
            if (query === this.state.searchText) {
              console.log({ results });
              this.setState({
                loading: false,
                total: total,
                hasNextPage,
                results,
              });
            }
          })
          .catch(error => {
            this.setState({ loading: false });
            console.log({ error });
          });
      }, 150);
    }
  }

  loadNextPage() {
    if (this.state.searchText.length > 0 && !this.state.loading) {
      this.setState({ loading: true });

      Elastic.search(this.state.searchText, this.state.results.length)
        .then(({ results, total, hasNextPage, query }) => {
          if (query === this.state.searchText) {
            this.setState({
              loading: false,
              hasNextPage,
              total,
              results: this.state.results.concat(
                results.map(r => {
                  const o = r._source;
                  o._id = r._id;
                  o._type = r._type;
                  return o;
                })
              ),
            });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log({ error });
        });
    }
  }

  renderItem(index, key) {
    const { windowSize } = this.props;
    const isMobile = windowSize.width <= 900;
    const listType = isMobile ? ListTypes.CardListY : ListTypes.CardListX;

    if (index >= this.state.results.length) {
      return <LoadingCell key={key} index={index} listType={listType} />;
    }

    const item = this.state.results[index];
    if (!item) {
      return <div key={key} />;
    }

    const onResultClick = () => {
      setTimeout(() => {
        this.props.onClose();
      }, 500);
    };

    console.log({ item });

    if (item._type === 'case-study') {
      console.log('Case Study');
      return (
        <CaseStudyResult
          key={key}
          caseStudy={item}
          listType={listType}
          onClick={onResultClick}
        />
      );
    }
    return <div>{item._type}</div>;
  }

  render() {
    const { windowSize, onClose } = this.props;
    const isMobile = windowSize.width <= 900;

    console.log({ total: this.state.total });

    return (
      <div className="search">
        <div className="search__container container--xl ma px15 pt15 f fc">
          <div className="f fr aic x">
            <div className="rel f fr border py025 f1">
              <div className="mx1 f aic jcc">
                <SearchIcon />
              </div>
              <input
                id="universal_search_field"
                className="search__field f1 cw mr05 small akkura caps"
                type="text"
                placeholder="SEARCH FOR CASE STUDIES"
                value={this.state.searchText}
                onChange={e => this.search(event.target.value)}
                size="1"
              />
              <div
                className={cx('clickable mx075 f aic jcc')}
                style={{ opacity: this.state.searchText.length === 0 ? 0 : 1 }}
                onClick={() => this.search('')}
              >
                <ClearCircle />
              </div>
            </div>
            <div className="small caps clickable ml1 z2" onClick={onClose}>
              CANCEL
            </div>
          </div>

          {isMobile ? (
            <div
              id="search-scroll-view"
              className="search__mobile_scrollview rel"
              onScroll={() => {
                if (this.state.total === 0) {
                  return;
                }
                const scrollView = document.getElementById('search-scroll-view');

                const percentage =
                  scrollView.scrollTop /
                  (scrollView.scrollHeight - scrollView.offsetHeight);
                const nextPageThreshold =
                  Math.max(this.state.results.length - 20, 0) / this.state.total;

                if (
                  percentage > nextPageThreshold &&
                  this.state.hasNextPage &&
                  !this.state.loading
                ) {
                  this.loadNextPage();
                }
              }}
            >
              <ReactList
                itemRenderer={(index, key) => this.renderItem(index, key)}
                length={this.state.total}
                type="uniform"
                axis="y"
                minSize={10}
              />
              {!this.state.loading && this.state.results.length === 0 && (
                <div className="abs x y top left f aic">
                  <div className="h1 x tw">
                    {this.state.searchText.length === 0
                      ? 'The truth is out there...'
                      : 'No results.'}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <ScrollView
                id="search-scroll-view"
                className="search__desktop_scrollview rel"
                hideScrollbars
                horizontal
                onScroll={(
                  x,
                  y,
                  contentWidth,
                  contentHeight,
                  containerWidth,
                  containerHeight
                ) => {
                  if (this.state.total === 0) {
                    return;
                  }
                  const percentage = x / (contentWidth - containerWidth);
                  if (this.sliderRef.current) {
                    this.sliderRef.current.setSliderValues([100 * percentage]);
                  }

                  const nextPageThreshold =
                    Math.max(this.state.results.length - 20, 0) / this.state.total;

                  if (
                    percentage > nextPageThreshold &&
                    this.state.hasNextPage &&
                    !this.state.loading
                  ) {
                    this.loadNextPage();
                  }
                }}
              >
                <ReactList
                  itemRenderer={(index, key) => this.renderItem(index, key)}
                  length={this.state.total}
                  type="uniform"
                  axis="x"
                />
                {!this.state.loading && this.state.results.length === 0 && (
                  <div className="abs x y top left f aic">
                    <div className="h1 x tw">
                      {this.state.searchText.length === 0
                        ? 'The truth is out there...'
                        : 'No results.'}
                    </div>
                  </div>
                )}
              </ScrollView>

              <div className="show--m mb1">
                {this.state.results.length > 0 ? (
                  <Slider
                    ref={this.sliderRef}
                    className={cx('slider', {
                      op0: this.state.results.length === 0,
                    })}
                    start={[100 * this.state.scrollPercentage]}
                    range={{ min: 0, max: 100 }}
                    connect={[true, false]}
                    orientation="horizontal"
                    onSlide={value => {
                      const scrollPercentage = parseFloat(value) / 100;
                      const scrollView = document.getElementById(
                        'search-scroll-view'
                      );
                      if (scrollView && scrollView.childNodes.length > 0) {
                        const contentWidth = scrollView.childNodes[0].scrollWidth;
                        scrollView.scrollLeft = scrollPercentage * contentWidth;
                      }
                    }}
                  />
                ) : (
                  <div className="bcg height-2" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const SearchWrapper = props => {
  const windowSize = useWindowSize();
  return <Search windowSize={windowSize} {...props} />;
};

export default SearchWrapper;
