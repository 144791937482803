import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import store from '../../state/store.js';
import { Provider } from '@picostate/react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import Transition from './transition.js';
import '../../styles/main.css';

class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      marginTop: 80,
      loaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const comp = document.getElementById('header__site');
      const headerHeight = comp ? comp.clientHeight : 0;
      store.hydrate({ marginTop: headerHeight })();
    }, 150);

    setTimeout(() => this.setState({ loaded: true }), 500);

    const resetState = () => {
      const header = document.getElementById('header__site');
      if (header) {
        const headerHeight = header.clientHeight;
        store.hydrate({
          cartIsOpen: false,
          nav: false,
          marginTop: headerHeight,
        })();
      }
    };

    window.addEventListener('locationchange', () => {
      setTimeout(() => resetState(), 100);
    });
    history.pushState = (f =>
      function pushState() {
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.pushState);

    history.replaceState = (f =>
      function replaceState() {
        const ret = f.apply(this, arguments);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
      })(history.replaceState);

    window.addEventListener('popstate', () => {
      window.dispatchEvent(new Event('locationchange'));
    });

    window.addEventListener('resize', resetState);
    window.addEventListener('scroll', () => {
      const header = document.getElementById('header__site');
      if (header) {
        const headerHeight = header.clientHeight;
        store.hydrate({
          marginTop: headerHeight,
        })();
      }
    });
    store.listen(state => {
      this.setState({ marginTop: state.marginTop });
    });

    console.groupCollapsed('Site credits 🍝');
    console.log('Developed by The Couch https://thecouch.nyc');
    console.log('Maintained by Laundromat https://laundromat.haus');
    console.log('Design by GrandArmy http://grandarmy.com/');
    console.log('An A24 Collaboration https://a24films.com/');
    console.log('Powered by Dropbox https://www.dropbox.com');
    console.groupEnd();
  }

  render() {
    return (
      <Provider store={store}>
        <div className={this.props.class}>
          <Helmet
            title="SpaceAgency"
            meta={[
              { name: 'description', content: 'SpaceAgency' },
              {
                name: 'keywords',
                content: 'super cluster, space, travel, space agency',
              },
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0',
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header siteTitle="SpaceAgency" location={this.props.location} />
          <div
            className="site__container"
            style={{
              marginTop: this.state.marginTop,
              transition: this.state.loaded ? 'margin 0.3s' : '',
            }}
          >
            <Transition location={this.props.location}>
              {this.props.children}
            </Transition>
          </div>
          <Footer />
        </div>
      </Provider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
