import React from 'react';
import { Link } from 'gatsby';
import { connect } from '@picostate/react';
import cx from 'classnames';

import Next from './next.js';
import Search from './search.js';
import store from '../../state/store.js';
import Logo from '../svgs/space-agency-logo.js';
import IGIcon from '../svgs/ig-icon.js';
import RSSIcon from '../svgs/rss-icon.js';
import SearchIcon from '../svgs/search-icon.js';
import TwitterIcon from '../svgs/twitter-icon.js';
import InlineVideo from '../block-content/inline-video.js';

const updateStates = (url = '') => {
  store.hydrate({
    nav: false,
    url,
  });
};

const pages = ['Work', 'About', 'Contact'];
const linkForPage = page => {
  let link = page.toLowerCase();
  if (page === 'Work') {
    link = '';
  }
  return link;
};

class HeaderWrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      path: 'Work',
      searchOpen: false,
    };
  }

  componentDidMount() {
    const path = window.location.pathname;
    if (path === '/') {
      this.setState({ path: 'Work' });
    } else if (path.startsWith('/about')) {
      this.setState({ path: 'About' });
    } else if (path.startsWith('/contact')) {
      this.setState({ path: 'Contact' });
    }
  }

  toggleHamburger() {
    if (this.props.nav) {
      this.closeHamburger();
    } else {
      this.openHamburger();
    }
  }

  openHamburger() {
    this.props.hydrate(state => ({
      nav: !this.props.nav,
      visible: true,
    }))();

    this.closeSearch();
  }

  closeHamburger() {
    if (this.props.nav) {
      this.props.hydrate(state => ({
        nav: false,
        visible: true,
      }))();
      setTimeout(() => {
        console.log('hide');
        this.props.hydrate(state => ({ visible: false }))();
      }, 300);
    }
  }

  openSearch() {
    if (!this.state.searchOpen) {
      const comp = document.getElementById('header__site');
      const headerHeight = comp ? comp.clientHeight : 0;
      const isMobile = window.innerWidth <= 900;
      const marginTop = isMobile ? window.innerHeight : headerHeight + 385;

      this.setState({ searchOpen: true });
      this.props.hydrate(state => ({
        nav: false,
        marginTop,
      }))();
      setTimeout(() => {
        document.getElementById('universal_search_field').focus();
      }, 250);
    }
  }

  closeSearch() {
    if (this.state.searchOpen) {
      const navHeight = 70;

      this.setState({ searchOpen: false });
      this.props.hydrate(state => ({
        marginTop: navHeight,
      }))();
    }
  }

  mobileHeader() {
    const { props } = this;
    return (
      <div className="hide--m x header__bar">
        <div className="x y f aic px15">
          <div className="x f fr aic">
            <div className="header__mobile__bar_buttons_left">
              <div
                role="button"
                aria-label="search"
                className="clickable"
                onClick={() => this.openSearch()}
              >
                <SearchIcon />
              </div>
            </div>
            <Link
              onClick={() => {
                this.setState({ path: 'Stories' });
                updateStates('/');
              }}
              to={'/'}
              className="f1 f aic jcc"
            >
              <Logo className="header__site__logo" />
            </Link>

            <div
              className="header__mobile__bar_buttons_right jce"
              style={{
                width: '45px',
              }}
            >
              <div
                role="button"
                aria-label="Navigation"
                className="header__site__toggle_container clickable"
                onClick={e => this.toggleHamburger()}
              >
                <div
                  className={`header__site__toggle f jcc aic ${props.nav && 'open'}`}
                >
                  <span className="header__site__toggle__lines cb bcb x" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  desktopHeader() {
    const { props } = this;
    return (
      <div className="show--m x header__bar">
        <div className="x y f aic px15">
          <div className="x f fr aic jcc f1">
            <Link
              onClick={() => {
                this.setState({ path: 'Stories' });
                updateStates('/');
              }}
              to={'/'}
            >
              <Logo className="header__site__logo mr2 p0" />
            </Link>
            <nav
              className="f1 f fr"
              role="navigation"
              aria-label="Global"
              aria-hidden={props.nav}
            >
              <ul
                className={cx('f1 f fr jce aic', {
                  header__enabled: !props.nav,
                  header__disabled: props.nav,
                })}
              >
                {pages.map(page => (
                  <li
                    key={page}
                    className={cx('caps s0 header__site-nav_link')}
                    style={{
                      borderBottom:
                        this.state.path === page ? '2px solid white' : '',
                    }}
                  >
                    <Link
                      className={cx('', { active: this.state.path === page })}
                      onClick={() => {
                        this.setState({ path: page });
                        updateStates(linkForPage(page));
                      }}
                      to={`/${linkForPage(page)}`}
                      aria-hidden={!props.nav}
                    >
                      {page}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            <div
              role="button"
              className="clickable mx1"
              onClick={() => this.openSearch()}
            >
              <SearchIcon />
            </div>
            <div className="width-1 bcdg header__site-divider mr1" />

            <div
              role="button"
              className="header__site__toggle_container clickable"
              onClick={e => this.toggleHamburger()}
            >
              <div
                className={`header__site__toggle f jcc aic ${props.nav && 'open'}`}
              >
                <span className="header__site__toggle__lines cb bcw x" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  hamburgerMenu() {
    const { props } = this;
    return props.visible ? (
      <div
        aria-hidden={!props.nav}
        onClick={e => {
          const noRedirect = '.newsletter_module *';
          if (!e.target.matches(noRedirect)) {
            this.closeHamburger();
          }
        }}
        className={cx(`header__mobile fix x y top f aic left bcb z4`, {
          open: props.nav,
          visible: props.visible,
        })}
      >
        <div
          className="container--xl ma"
          style={{ width: '100vw', height: 'calc(80vh - 150px)' }}
        >
          <div className="x f fr">
            <nav className="f1">
              <ul className="f fc">
                {pages.map(page => (
                  <li key={page} className={'px15 py075'}>
                    <Link
                      className={cx('header__hamburger__link h2 asfs', {
                        header__hamburger__link_active: this.state.path === page,
                      })}
                      onClick={() => {
                        this.setState({ path: page });
                        updateStates(linkForPage(page));
                        this.closeHamburger();
                      }}
                      to={`/${linkForPage(page)}`}
                      aria-hidden={!props.nav}
                    >
                      {page}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="abs bottom left x">
            <div className="container--xl ma p15 f fr">
              <div className="f1">
                <a
                  href="https://twitter.com/SuperclusterHQ"
                  target="blank"
                  className="ml075 clickable"
                  alt="Twitter"
                  aria-label="Twitter"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.instagram.com/superclusterhq/"
                  target="blank"
                  className="ml075 clickable"
                  alt="Instagram"
                  aria-label="Instagram"
                >
                  <IGIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div />
    );
  }

  render() {
    const { props } = this;
    return (
      <div>
        {this.hamburgerMenu()}
        <div className={`header js-header ${props.open && 'open'}`}>
          <div className={cx('header__overlay fix top left fill')} />

          <div
            className={cx('header__site fix top left x z5 bcb')}
            id="header__site"
          >
            <div className="container--xl rel ma f fr aic jcc header__site-container">
              {this.mobileHeader()}
              {this.desktopHeader()}
            </div>

            <div
              className={cx('header__search x ovfh', {
                header__search_open: this.state.searchOpen,
              })}
            >
              <Search onClose={() => this.closeSearch()} />
            </div>

            <div className="bcb">
              <div
                className={cx('header__launch', {
                  // header__disabled: props.nav,
                  // header__enabled: !props.nav
                })}
              >
                <Next />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Header = connect(state => ({
  nav: state.nav,
  visible: state.visible,
  open: state.cartIsOpen,
  marginTop: state.marginTop,
}))(props => (
  <HeaderWrapper
    open={props.open}
    visible={props.visible}
    location={props.location}
    nav={props.nav}
    hydrate={props.hydrate}
    marginTop={props.marginTop}
  />
));

export default Header;
