import React, { PureComponent } from 'react';
import classnames from 'classnames';
import debounce from 'debounce';

const SCROLL_END_DEBOUNCE = 300;
const ACTIVIATION_DISTANCE = 15;
const STOP_PROPOGATION = false;

class ScrollView extends PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.onEndScroll = debounce(this.onEndScroll, SCROLL_END_DEBOUNCE);
    this.scrolling = false;
    this.started = false;
    this.pressed = false;

    this.state = { scrollPercentage: 0 };
  }

  componentDidMount() {
    const nativeMobileScroll = true;
    const container = this.container.current;

    window.addEventListener('mouseup', this.onMouseUp);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('touchmove', this.onTouchMove, { passive: false });
    window.addEventListener('touchend', this.onTouchEnd);

    // due to https://github.com/facebook/react/issues/9809#issuecomment-414072263
    container.addEventListener('touchstart', this.onTouchStart, { passive: false });
    container.addEventListener('mousedown', this.onMouseDown, { passive: false });

    if (nativeMobileScroll) {
      // We should check if it's the mobile device after page was loaded
      // to prevent breaking SSR
      this.isMobile = this.isMobileDevice();

      // If it's the mobile device, we should rerender to change styles
      if (this.isMobile) {
        this.forceUpdate();
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      scrollPercentage: props.scrollPercentage,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);
  }

  getElement() {
    return this.container.current;
  }

  isMobileDevice() {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  }

  isDraggable(target) {
    const ignoreElements = this.props.ignoreElements;
    if (ignoreElements) {
      const closest = target.closest(ignoreElements);
      return closest === null || closest.contains(this.getElement());
    } else {
      return true;
    }
  }

  isScrollable() {
    const container = this.container.current;
    return (
      container &&
      (container.scrollWidth > container.clientWidth ||
        container.scrollHeight > container.clientHeight)
    );
  }

  // Simulate 'onEndScroll' event that fires when scrolling is stopped
  onEndScroll = () => {
    this.scrolling = false;
    if (!this.pressed && this.started) {
      this.processEnd();
    }
  };

  onScroll = e => {
    const container = this.container.current;
    // Ignore the internal scrolls
    if (
      container.scrollLeft !== this.scrollLeft ||
      container.scrollTop !== this.scrollTop
    ) {
      this.scrolling = true;
      this.processScroll(e);
      this.onEndScroll();
    }
  };

  onTouchStart = e => {
    const { nativeMobileScroll } = this.props;
    if (this.isDraggable(e.target)) {
      if (nativeMobileScroll && this.scrolling) {
        this.pressed = true;
      } else {
        const touch = e.touches[0];
        this.processClick(e, touch.clientX, touch.clientY);
        if (!nativeMobileScroll && STOP_PROPOGATION) {
          e.stopPropagation();
        }
      }
    }
  };

  onTouchEnd = e => {
    const { nativeMobileScroll } = this.props;
    if (this.pressed) {
      if (this.started && (!this.scrolling || !nativeMobileScroll)) {
        this.processEnd();
      } else {
        this.pressed = false;
      }
      this.forceUpdate();
    }
  };

  onTouchMove = e => {
    const { nativeMobileScroll } = this.props;
    if (this.pressed && (!nativeMobileScroll || !this.isMobile)) {
      const touch = e.touches[0];
      if (touch) {
        this.processMove(e, touch.clientX, touch.clientY);
      }
      e.preventDefault();
      if (STOP_PROPOGATION) {
        e.stopPropagation();
      }
    }
  };

  onMouseDown = e => {
    if (this.isDraggable(e.target) && this.isScrollable()) {
      this.processClick(e, e.clientX, e.clientY);
      e.preventDefault();
      if (STOP_PROPOGATION) {
        e.stopPropagation();
      }
    }
  };

  onMouseMove = e => {
    if (this.pressed) {
      this.processMove(e, e.clientX, e.clientY);
      e.preventDefault();
      if (STOP_PROPOGATION) {
        e.stopPropagation();
      }
    }
  };

  onMouseUp = e => {
    if (this.pressed) {
      if (this.started) {
        this.processEnd();
      } else {
        this.pressed = false;
        this.forceUpdate();
        if (this.props.onClick) {
          this.props.onClick(e);
        }
      }
      e.preventDefault();
      if (STOP_PROPOGATION) {
        e.stopPropagation();
      }
    }
  };

  processClick(e, clientX, clientY) {
    const container = this.container.current;
    this.scrollLeft = container.scrollLeft;
    this.scrollTop = container.scrollTop;
    this.clientX = clientX;
    this.clientY = clientY;
    this.pressed = true;
  }

  processStart(e, changeCursor = true) {
    const { onStartScroll } = this.props;
    const container = this.container.current;

    this.started = true;

    // Add the class to change displayed cursor
    if (changeCursor) {
      document.body.classList.add('scroll-view-dragging');
    }

    if (onStartScroll) {
      onStartScroll(
        container.scrollLeft,
        container.scrollTop,
        container.scrollWidth,
        container.scrollHeight
      );
    }
    this.forceUpdate();
  }

  // Process native scroll (scrollbar, mobile scroll)
  processScroll(e) {
    if (this.started) {
      const { onScroll } = this.props;
      const container = this.container.current;

      if (onScroll) {
        onScroll(
          container.scrollLeft,
          container.scrollTop,
          container.scrollWidth,
          container.scrollHeight,
          container.clientWidth,
          container.clientHeight
        );
      }
    } else {
      this.processStart(e, false);
    }
  }

  // Process non-native scroll
  processMove(e, newClientX, newClientY) {
    const { horizontal, vertical, onScroll } = this.props;
    const container = this.container.current;

    if (!this.started) {
      if (
        (horizontal && Math.abs(newClientX - this.clientX) > ACTIVIATION_DISTANCE) ||
        (vertical && Math.abs(newClientY - this.clientY) > ACTIVIATION_DISTANCE)
      ) {
        this.clientX = newClientX;
        this.clientY = newClientY;
        this.processStart();
      }
    } else {
      if (horizontal) {
        container.scrollLeft -= newClientX - this.clientX;
      }
      if (vertical) {
        container.scrollTop -= newClientY - this.clientY;
      }
      if (onScroll) {
        onScroll(
          container.scrollLeft,
          container.scrollTop,
          container.scrollWidth,
          container.scrollHeight,
          container.clientWidth,
          container.clientHeight
        );
      }
      this.clientX = newClientX;
      this.clientY = newClientY;
      this.scrollLeft = container.scrollLeft;
      this.scrollTop = container.scrollTop;
    }
  }

  processEnd(e) {
    const { onEndScroll } = this.props;
    const container = this.container.current;

    this.pressed = false;
    this.started = false;
    this.scrolling = false;

    if (container && onEndScroll) {
      onEndScroll(
        container.scrollLeft,
        container.scrollTop,
        container.scrollWidth,
        container.scrollHeight
      );
    }
    document.body.classList.remove('scroll-view-dragging');
    this.forceUpdate();
  }

  render() {
    const { id, children, className, style, hideScrollbars } = this.props;

    return (
      <div
        id={id || null}
        className={classnames(className, {
          'scroll-view--dragging': this.pressed,
          'scroll-view--hide-scrollbars': hideScrollbars,
          'scroll-view--native-scroll': this.isMobile,
        })}
        style={style || {}}
        ref={this.container}
        onScroll={this.onScroll}
      >
        {children}
      </div>
    );
  }
}

export default ScrollView;
