import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
// import contentfulAPI from '../api/contentful'
import store from '../../state/store.js';
import spacetime from 'spacetime';

import sanity from '../../api/sanity';

const pad = (num, size) => {
  num = Math.max(0, num);
  let s = String(num);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const isDST = d => {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
};

const updateStates = () => {
  store.hydrate({
    cartIsOpen: false,
    nav: false,
  });
};

const Next = () => {
  const [launch, setLaunch] = useState(null);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    const time = new Date();
    const windowBegin = spacetime(time).subtract(1, 'hour');
    const windowEnd = spacetime(time).add(1, 'hour');
    const cutoffISO = windowBegin.d.toISOString();
    const queryLaunches = `*[_type == "launch" && launchInfo.launchDate.utc > '${cutoffISO}'] | order(launchInfo.launchDate.utc) [0..10]  {
        ...,
        'launchImageId': image.asset._ref
      }`;

    sanity.fetch(queryLaunches).then(response => {
      const publishedLaunches = (response || []).filter(l => {
        const age = spacetime(l._createdAt).diff(spacetime('now'), 'minute');
        return age >= 10;
      });

      const nextHour = publishedLaunches.filter(l => {
        return (
          spacetime(l.launchInfo.launchDate.utc).isAfter(spacetime()) &&
          spacetime(l.launchInfo.launchDate.utc).isBefore(windowEnd)
        );
      });

      const highlightedLaunch =
        nextHour.length > 0 ? nextHour[0] : publishedLaunches[0];
      setLaunch(highlightedLaunch);
      store.hydrate({
        activeLaunch: highlightedLaunch.slug.current,
      });
    });
  }, [0]);

  const getTimeRemaining = endtime => {
    const { launchSeconds } = launch.launchInfo;
    const end = spacetime('now');
    const epochTime = end.epoch;
    const endEpoch = spacetime(endtime.utc, 'UTC');

    if (isDST(new Date(endtime.utc))) {
      // epochTime += 3600000
    }

    let t = endEpoch.epoch - epochTime;
    if (launchSeconds) {
      const newTime = endEpoch.epoch + launchSeconds * 1000;
      t = newTime - epochTime;
    }

    setSeconds(Math.floor((t / 1000) % 60));
    setMinutes(Math.floor((t / 1000 / 60) % 60));
    setHours(Math.floor((t / (1000 * 60 * 60)) % 24));
    setDays(Math.floor(t / (1000 * 60 * 60 * 24)));
  };

  useEffect(() => {
    if (days < 0 && hours < 0 && minutes < -10) {
      const end = spacetime('now');
      const date = new Date();
      if (end.isDST()) {
        date.setHours(date.getHours() + 1);
      }
      const windowBegin = spacetime(date).subtract(1, 'hour');
      const windowEnd = spacetime(date).add(1, 'hour');
      const queryLaunches = `*[_type == "launch" && launchInfo.launchDate.utc > '${windowBegin.d.toISOString()}'] | order(launchInfo.launchDate.utc) [0..10]  {
        ...,
        'launchImageId': image.asset._ref
      }`;

      console.log('Fetch next 2');
      sanity.fetch(queryLaunches).then(response => {
        const nextHour = response.filter(l => {
          return (
            spacetime(l.launchInfo.launchDate.utc).isAfter(spacetime()) &&
            spacetime(l.launchInfo.launchDate.utc).isBefore(windowEnd)
          );
        });

        const highlightedLaunch = nextHour.length > 0 ? nextHour[0] : response[0];
        setLaunch(highlightedLaunch);
        store.hydrate({
          activeLaunch: highlightedLaunch.slug.current,
        });
      });
    }
  }, [days, hours, minutes]);

  useInterval(
    () => {
      if (launch) {
        getTimeRemaining(launch.launchInfo.launchDate);
      }
    },
    isRunning ? 1000 : null
  );

  return (
    <div className="bcb cw launch__next__container">
      <div className="container--xl ma">
        <div className="launch__next f px15 jcs aic rel">
          {launch && (
            <Link
              onClick={() => updateStates()}
              to={`https://supercluster.com/launches/${launch.slug.current}`}
              className="abs z1 left top x y"
            />
          )}
          <h6 className="bau caps m0 p0 f small akkura rel launch__next-title aic">
            Next Launch:
          </h6>
          {/* <Link to={`/launches/${launch.node.uid}`}>{launch.node.data.launch_date}</Link> */}
          {launch && launch.launchInfo.launchDate ? (
            <div className="bau-bold h4 m0 ml05 p0 launch__next-timer js-launch-timer">
              <span className="p05">{pad(days)}</span>
              <span className="xsmall mr1">D</span>:
              <span className="p05">{pad(hours)}</span>
              <span className="xsmall mr1">H</span>:
              <span className="p05">{pad(minutes)}</span>
              <span className="xsmall mr1">M</span>:
              <span className="p05">{pad(seconds)}</span>
              <span className="xsmall mr1">S</span>
            </div>
          ) : (
            <span className="sans h4 m0 ml1 p0">Calculating...</span>
          )}
          {launch && (
            <span className="ml1 pl1 rel akkura launch__next-mini js-mini caps small">
              {launch.launchInfo.launchMiniDescription}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Next;
