/* eslint-disable no-extend-native */
import { useState, useCallback } from 'react';
import qs from 'query-string';
import spacetime from 'spacetime';

Number.prototype.pad = function(size) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

const getDateComponents = isoDateString => {
  if (!isoDateString || isoDateString.length === 0) {
    console.error('Bad date sent to getShortDisplayTime!');
  }

  const total = Date.parse(isoDateString) - Date.parse(new Date());

  const rawSeconds = Math.floor((total / 1000) % 60);
  const rawMinutes = Math.floor((total / 1000 / 60) % 60);
  const rawHours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const rawDays = Math.floor(total / (1000 * 60 * 60 * 24));

  const seconds = Math.max(rawSeconds, 0);
  const minutes = Math.max(rawMinutes, 0);
  const hours = Math.max(rawHours, 0);
  const days = Math.max(rawDays, 0);

  const isPast = total < 0;
  const isLaunching = total > -(60 * 60) && total < 60 * 60;

  let launchState = '';
  if (isLaunching) {
    launchState = isPast ? 'Just Launched' : 'Launching Soon';
  } else if (isPast) {
    launchState = 'Past';
  } else {
    launchState = 'Upcoming';
  }

  const calendarDate = spacetime(isoDateString).format(
    '{month-short} {date}, {year}'
  );

  let shortDescription = '';
  if (!isPast) {
    if (days > 30) {
      shortDescription = calendarDate;
    } else if (!isLaunching) {
      shortDescription =
        days > 0
          ? `${days} ${days > 1 || days === 0 ? 'days' : 'day'}`
          : `${hours} ${hours > 1 || hours === 0 ? 'hours' : 'hour'}`;
    } else {
      shortDescription = `${days.pad(2)}:${hours.pad(2)}:${minutes.pad(
        2
      )}:${seconds.pad(2)}`;
    }
  } else {
    if (isLaunching) {
      shortDescription = '00:00:00:00';
    } else if (rawDays > -30) {
      const daysAgo = Math.abs(rawDays);
      const hoursAgo = Math.abs(rawHours);
      shortDescription =
        daysAgo > 1
          ? `${daysAgo} ${daysAgo > 1 || daysAgo === 0 ? 'days ago' : 'day ago'}`
          : `${hoursAgo} ${
              hoursAgo > 1 || hoursAgo === 0 ? 'hours ago' : 'hour ago'
            }`;
    } else {
      shortDescription = calendarDate;
    }
  }

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
    rawDays,
    rawHours,
    rawMinutes,
    rawSeconds,
    isPast,
    isLaunching,
    shortDescription,
    calendarDate,
    launchState,
  };
};

const getTimeInSpace = astronaut => {
  const { totalMinutesInSpace } = astronaut;
  if (totalMinutesInSpace === undefined) {
    return 'Unknown';
  }
  const t = totalMinutesInSpace * 60 * 1000;
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return `${days.pad(2)}:${hours.pad(2)}:${minutes.pad(2)}`;
};

const getSpacewalkTime = astronaut => {
  const { totalSecondsSpacewalking } = astronaut;
  if (totalSecondsSpacewalking === undefined) {
    return 'Unknown';
  }
  const t = totalSecondsSpacewalking * 1000;
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return `${days.pad(2)}:${hours.pad(2)}:${minutes.pad(2)}`;
};

const setQueryStringWithoutPageReload = qsValue => {
  if (typeof window !== `undefined`) {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      qsValue;
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

const setQueryValue = (key, value) => {
  if (typeof window !== `undefined`) {
    const values = qs.parse(window.location.search);
    const newQsValue = qs.stringify({ ...values, [key]: value });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
  }
};

const getQueryStringValue = (key, initialValue) => {
  if (typeof window !== `undefined`) {
    const values = qs.parse(window.location.search);
    return values[key] || initialValue;
  }
};

const useQueryString = (key, initialValue) => {
  const [value, setValue] = useState(getQueryStringValue(key, initialValue));
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
};

const getQueryBoolValue = (key, initialValue) => {
  let value = initialValue;
  const qsValue = getQueryStringValue(key);
  if (qsValue) {
    value = qsValue === 'true';
  }
  return value;
};

const useQueryBool = (key, initialValue) => {
  const initVal = getQueryBoolValue(key, initialValue);

  const [value, setValue] = useState(initVal);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
};

const getQueryNumberValue = (key, initialValue) => {
  let value = initialValue;
  const qsValue = getQueryStringValue(key);
  if (qsValue) {
    value = parseInt(qsValue, 10);
  }
  return value;
};

const useQueryNumber = (key, initialValue) => {
  const initVal = getQueryNumberValue(key, initialValue);

  const [value, setValue] = useState(initVal);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
};

const formatSimpleDate = date => {
  const components = date.split('-');
  if (components.length < 3) {
    return '';
  }

  const month = parseInt(components[1], 10);
  const day = parseInt(components[2], 10);
  const year = parseInt(components[0], 10);

  const abbMonth = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  }[month];

  return `${abbMonth} ${day}, ${year}`;
};

const throttle = (callback, limit, time) => {
  /// monitor the count
  let calledCount = 0;

  /// refresh the `calledCount` varialbe after the `time` has been passed
  setInterval(function() {
    calledCount = 0;
  }, time);

  /// creating a closure that will be called
  return function() {
    /// checking the limit (if limit is exceeded then do not call the passed function
    if (limit > calledCount) {
      /// increase the count
      calledCount++;
      callback(); /// call the function
    } else console.log('not calling because the limit has exceeded');
  };
};

export default {
  getDateComponents,
  formatSimpleDate,

  getTimeInSpace,
  getSpacewalkTime,

  setQueryStringWithoutPageReload,

  getQueryStringValue,
  getQueryBoolValue,
  getQueryNumberValue,

  setQueryValue,

  useQueryString,
  useQueryBool,
  useQueryNumber,

  throttle,
};
