import createStore from 'picostate';

const initialState = () => {
  return {
    mobileNavIsOpen: false,
    cartIsOpen: false,
    activeLaunch: null,
    imageOpen: true,
    marginTop: 70,
  };
};

export default createStore({}); // initialState()
