import React from 'react';

export default function SVG({ className }) {
  return (
    <svg
      height="29px"
      viewBox="0 0 103 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Page-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="space-agency-logo" fill="#FFFFFF">
          <path
            d="M28.5081818,11.3154545 L28.5081818,28.5381818 C23.7518182,28.5381818 19.8954545,24.6818182 19.8954545,19.9281818 C19.8954545,15.1718182 23.7518182,11.3154545 28.5081818,11.3154545 M9.91363636,12.0818182 C7.10454545,16.3772727 5.19818182,19.29 -1.42108547e-14,19.89 L-1.42108547e-14,28.59 C9.95454545,27.84 13.95,21.7418182 17.1654545,16.8245455 C20.0754545,12.3736364 22.2354545,9.07090909 28.5081818,8.67272727 L28.5081818,0 C17.5118182,0.474545455 13.3118182,6.88363636 9.91363636,12.0818182"
            id="Fill-136"
          ></path>
          <path
            d="M37.4462727,8.92227273 L38.7717273,8.64954545 C39.1235455,10.0077273 39.6499091,11.1559091 42.1071818,11.1559091 C43.7680909,11.1559091 44.9953636,10.4386364 44.9953636,8.82681818 C44.9953636,7.34318182 44.1662727,7.02409091 41.8671818,6.57681818 C39.2653636,6.06409091 37.7326364,5.44227273 37.7326364,3.14590909 C37.7326364,1.245 39.2026364,0.0313636364 41.6271818,0.0313636364 C44.1662727,0.0313636364 45.189,0.988636364 45.8108182,2.85681818 L44.5808182,3.17590909 C44.0544545,1.65954545 43.3044545,1.14954545 41.7062727,1.14954545 C39.7453636,1.14954545 39.0253636,1.93227273 39.0253636,3.08045455 C39.0253636,4.43863636 39.6171818,4.77136364 42.1071818,5.25136364 C44.7880909,5.76136364 46.3371818,6.43227273 46.3371818,8.79409091 C46.3371818,11.0768182 44.6762727,12.3204545 42.1071818,12.3204545 C39.0417273,12.3204545 37.9099091,10.8204545 37.4462727,8.92227273"
            id="Fill-138"
          ></path>
          <path
            d="M48.6081818,0.175909091 L53.6509091,0.175909091 C55.9663636,0.175909091 57.5318182,1.27772727 57.5318182,3.54409091 C57.5318182,5.715 55.9336364,6.78409091 53.6509091,6.78409091 L49.9963636,6.78409091 L49.9963636,12.195 L48.6081818,12.195 L48.6081818,0.175909091 Z M53.7627273,5.66590909 C55.3581818,5.66590909 56.1409091,4.85318182 56.1409091,3.465 C56.1409091,1.97863636 55.2954545,1.32409091 53.7627273,1.32409091 L49.9963636,1.32409091 L49.9963636,5.66590909 L53.7627273,5.66590909 Z"
            id="Fill-140"
          ></path>
          <path
            d="M58.5248182,12.1939091 L57.1693636,12.1939091 L61.4457273,0.161181818 L62.5611818,0.161181818 L66.7911818,12.1939091 L65.3539091,12.1939091 L64.113,8.57209091 L59.7684545,8.57209091 L58.5248182,12.1939091 Z M61.9393636,2.21754545 L60.1693636,7.40481818 L63.7120909,7.40481818 L61.9393636,2.21754545 Z"
            id="Fill-143"
          ></path>
          <path
            d="M67.4839091,6.19254545 C67.4839091,2.63345455 69.6875455,0.00163636364 72.9111818,0.00163636364 C75.303,0.00163636364 76.8848182,1.038 77.7139091,3.46254545 L76.4866364,3.89618182 C75.9439091,2.10709091 74.8257273,1.14981818 72.9411818,1.14981818 C70.4839091,1.14981818 68.9048182,3.15981818 68.9048182,6.17618182 C68.9048182,9.24163636 70.4839091,11.1561818 72.9902727,11.1561818 C74.8257273,11.1561818 76.0720909,10.2316364 76.6120909,8.31436364 L77.8584545,8.778 C77.0593636,11.1398182 75.4175455,12.3370909 72.9575455,12.3370909 C69.5893636,12.3370909 67.4839091,9.75163636 67.4839091,6.19254545"
            id="Fill-144"
          ></path>
          <polygon
            id="Fill-146"
            points="79.7462727 0.175909091 87.4235455 0.175909091 87.4235455 1.34045455 81.1344545 1.34045455 81.1344545 5.57045455 86.3544545 5.57045455 86.3544545 6.70227273 81.1344545 6.70227273 81.1344545 11.0277273 87.7262727 11.0277273 87.7262727 12.195 79.7462727 12.195"
          ></polygon>
          <path
            d="M38.8030909,28.3947273 L37.4476364,28.3947273 L41.724,16.362 L42.8421818,16.362 L47.0694545,28.3947273 L45.6349091,28.3947273 L44.3912727,24.7729091 L40.0494545,24.7729091 L38.8030909,28.3947273 Z M42.2176364,18.4183636 L40.4476364,23.6056364 L43.9903636,23.6056364 L42.2176364,18.4183636 Z"
            id="Fill-147"
          ></path>
          <path
            d="M47.7619091,22.3933636 C47.7619091,18.8833636 49.9000909,16.1997273 53.1728182,16.1997273 C55.5182727,16.1997273 57.0837273,17.1897273 57.9755455,19.5051818 L56.7319091,19.9851818 C56.1264545,18.1960909 55.0873636,17.3506364 53.2055455,17.3506364 C50.6500909,17.3506364 49.1828182,19.4397273 49.1828182,22.377 C49.1828182,25.347 50.6200909,27.3733636 53.2191818,27.3733636 C55.3600909,27.3733636 57.0019091,25.7124545 57.0019091,23.2879091 L57.0019091,23.1106364 L53.7482727,23.1106364 L53.7482727,21.9460909 L58.3600909,21.9460909 L58.3600909,28.3960909 L57.4655455,28.3960909 L57.1955455,26.1597273 C56.3500909,27.6924545 55.041,28.5379091 53.1891818,28.5379091 C49.7882727,28.5379091 47.7619091,25.9033636 47.7619091,22.3933636"
            id="Fill-148"
          ></path>
          <polygon
            id="Fill-150"
            points="60.6638182 16.3764545 68.3410909 16.3764545 68.3410909 17.541 62.052 17.541 62.052 21.771 67.272 21.771 67.272 22.9028182 62.052 22.9028182 62.052 27.2282727 68.6438182 27.2282727 68.6438182 28.3955455 60.6638182 28.3955455"
          ></polygon>
          <polygon
            id="Fill-151"
            points="70.4844545 16.3764545 72.129 16.3764545 78.4971818 26.6064545 78.4971818 16.3764545 79.8062727 16.3764545 79.8062727 28.3955455 78.0335455 28.3955455 71.7935455 18.3728182 71.7935455 28.3955455 70.4844545 28.3955455"
          ></polygon>
          <path
            d="M82.062,22.3933636 C82.062,18.8342727 84.2656364,16.1997273 87.4892727,16.1997273 C89.8838182,16.1997273 91.4629091,17.2388182 92.2947273,19.6633636 L91.0647273,20.097 C90.522,18.3079091 89.4038182,17.3506364 87.5192727,17.3506364 C85.062,17.3506364 83.4829091,19.3606364 83.4829091,22.377 C83.4829091,25.4424545 85.062,27.357 87.5683636,27.357 C89.4038182,27.357 90.6501818,26.4324545 91.1901818,24.5151818 L92.4365455,24.9788182 C91.6374545,27.3406364 89.9956364,28.5379091 87.5383636,28.5379091 C84.1674545,28.5379091 82.062,25.9524545 82.062,22.3933636"
            id="Fill-152"
          ></path>
          <polygon
            id="Fill-154"
            points="92.8429091 16.3764545 94.4383636 16.3764545 97.8065455 21.9619091 101.062909 16.3764545 102.483818 16.3764545 98.4610909 23.1755455 98.4610909 28.3955455 97.0729091 28.3955455 97.0729091 23.1591818"
          ></polygon>
        </g>
      </g>
    </svg>
  );
}
